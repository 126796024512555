// src/main.ts
import { Application, Container, Sprite, Texture, Assets, Ticker, Graphics } from 'pixi.js';
import { Text, TextStyle, HTMLText } from 'pixi.js';
import { sound } from '@pixi/sound';

// Import images
import fcc1 from './assets/images/fc/c1.png';
import fcc2 from './assets/images/fc/c2.png';
import fcc3 from './assets/images/fc/c3.png';
import fcc4 from './assets/images/fc/c4.png';
import fcc5 from './assets/images/fc/c5.png';
import fcc6 from './assets/images/fc/c6.png';
import fcc7 from './assets/images/fc/c7.png';
import fcc8 from './assets/images/fc/c8.png';
import fcc9 from './assets/images/fc/c9.png';
import fcc10 from './assets/images/fc/c10.png';
import fcc11 from './assets/images/fc/c11.png';
import fcc12 from './assets/images/fc/c12.png';
import fcc13 from './assets/images/fc/c13.png';
import fcc14 from './assets/images/fc/c14.png';
import fcc15 from './assets/images/fc/c15.png';
import fcc16 from './assets/images/fc/c16.png';
import fcc17 from './assets/images/fc/c17.png';
import fcc18 from './assets/images/fc/c18.png';
import fcc19 from './assets/images/fc/c19.png';
import fcc20 from './assets/images/fc/c20.png';
import fcc21 from './assets/images/fc/c21.png';
import fcc22 from './assets/images/fc/c22.png';
import fcc23 from './assets/images/fc/c23.png';
import fcc24 from './assets/images/fc/c24.png';
import fcc25 from './assets/images/fc/c25.png';
import fcc26 from './assets/images/fc/c26.png';
import fcc27 from './assets/images/fc/c27.png';

// Import images
import fcwc1 from './assets/images/fcw/c1.png';
import fcwc2 from './assets/images/fcw/c2.png';
import fcwc3 from './assets/images/fcw/c3.png';
import fcwc4 from './assets/images/fcw/c4.png';
import fcwc5 from './assets/images/fcw/c5.png';
import fcwc6 from './assets/images/fcw/c6.png';
import fcwc7 from './assets/images/fcw/c7.png';
import fcwc8 from './assets/images/fcw/c8.png';
import fcwc9 from './assets/images/fcw/c9.png';
import fcwc10 from './assets/images/fcw/c10.png';
import fcwc11 from './assets/images/fcw/c11.png';
import fcwc12 from './assets/images/fcw/c12.png';
import fcwc13 from './assets/images/fcw/c13.png';
import fcwc14 from './assets/images/fcw/c14.png';
import fcwc15 from './assets/images/fcw/c15.png';
import fcwc16 from './assets/images/fcw/c16.png';
import fcwc17 from './assets/images/fcw/c17.png';
import fcwc18 from './assets/images/fcw/c18.png';
import fcwc19 from './assets/images/fcw/c19.png';
import fcwc20 from './assets/images/fcw/c20.png';
import fcwc21 from './assets/images/fcw/c21.png';
import fcwc22 from './assets/images/fcw/c22.png';
import fcwc23 from './assets/images/fcw/c23.png';
import fcwc24 from './assets/images/fcw/c24.png';
import fcwc25 from './assets/images/fcw/c25.png';
import fcwc26 from './assets/images/fcw/c26.png';
import fcwc27 from './assets/images/fcw/c27.png';



// import symbol1 from './assets/images/symbol1.png';
// import symbol2 from './assets/images/symbol2.png';
// import symbol3 from './assets/images/symbol3.png';
// import symbol4 from './assets/images/symbol4.png';

// // Импорт 'win' версий символов
// import symbol1Win from './assets/images/symbol1-win.png';
// import symbol2Win from './assets/images/symbol2-win.png';
// import symbol3Win from './assets/images/symbol3-win.png';
// import symbol4Win from './assets/images/symbol4-win.png';

import smLogoImg from './assets/images/sm-logo.png';
import machineBg from './assets/images/machine-bg.png';
import activeSpinButton from './assets/images/spin-button-active.png';
import inactiveSpinButton from './assets/images/spin-button-inactive.png';
import refillButton from './assets/images/refill-button.png';

import { getUID, loadUID } from './utils/getUID';

interface Balance { amount: number, spins?: number }
interface SpinResult { numbers: number[] | null, lines: number[][] | null, multiplier: number, win: number | null, lose: number | null, balance: Balance | null }

let state: {
  balance?: Balance | null
}

const backgroundSound = new URL('./assets/sounds/background2.mp3', import.meta.url).toString()
const winSound = new URL('./assets/sounds/win.mp3', import.meta.url).toString()
const loseSound = new URL('./assets/sounds/lose.mp3', import.meta.url).toString()
const reelStopSound = new URL('./assets/sounds/reel-stop.mp3', import.meta.url).toString()

type VolumeController = { cancel: () => void } | null
const soundVolumes = {
  backgroundSound: { min: 0.1, max: 2, vc: null as VolumeController },
  reelStopSound: { min: 0.2, max: 3 },
  winSound: { min: 0.2, max: 4 },
  loseSound: { min: 0.2, max: 2 },
}
const playing = new Map<string, boolean>();

// Массивы символов
const symbols: string[] = [fcc1, fcc2, fcc3, fcc4, fcc5, fcc6, fcc7, fcc8, fcc9, fcc10, fcc11, fcc12, fcc13, fcc14, fcc15, fcc16, fcc17, fcc18, fcc19, fcc20, fcc21, fcc22, fcc23, fcc24, fcc25, fcc26, fcc27];
// const symbolsWin: string[] = [symbol1Win, symbol2Win, symbol3Win, symbol4Win];
const symbolsWin: string[] = [fcwc1, fcwc2, fcwc3, fcwc4, fcwc5, fcwc6, fcwc7, fcwc8, fcwc9, fcwc10, fcwc11, fcwc12, fcwc13, fcwc14, fcwc15, fcwc16, fcwc17, fcwc18, fcwc19, fcwc20, fcwc21, fcwc22, fcwc23, fcwc24, fcwc25, fcwc26, fcwc27];

const app = new Application();
const winLineGraphics = new Graphics();

let reels: Sprite[][] = [];
let spinButtonSprite: Sprite
let machineBgSprite: Sprite
let smLogoSprite: Sprite
let refillButtonSprite: Sprite

let resultText: Text;
let balanceText: Text;
let spinsText: Text;
let refillText: Text

// Объявление переменной для хранения ссылки на открытое окно
let refillWindow: Window | null = null;

let scale = 1 // масштаб, меняется при ресайзе
let reelsContainerDefaultWidth = 300
let reelsCell = 100


// Store loaded textures
let textures: Texture[] = [];
let texturesWin: Texture[] = [];

let smLogoTexture: Texture
let machineBgTexture: Texture
let activeSpinButtonTexture: Texture;
let inactiveSpinButtonTexture: Texture;
let refillButtontexture: Texture

let bgContainer: Container
// контейнер с барабанами
let reelsContainer: Container
// контейнер для линий
let graphicsContainer: Container

async function init() {
  try {

    console.log('loadUID', await (loadUID()))

    // await app.init({ width: 300, height: 600 });
    await app.init({
      resizeTo: window, // Автоматически подстраивает размер канваса под окно
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      backgroundColor: 0x000000,
    });

    document.getElementById('game-container')?.appendChild(app.canvas);
    await loadAssets();

    bgContainer = new Container()
    app.stage.addChild(bgContainer);
    app.stage.setChildIndex(bgContainer, 0)

    smLogoSprite = new Sprite(smLogoTexture);
    smLogoSprite.anchor.set(0.5, 0)
    bgContainer.addChild(smLogoSprite);

    machineBgSprite = new Sprite(machineBgTexture);
    machineBgSprite.anchor.set(0.5, 0)
    bgContainer.addChild(machineBgSprite);


    // Создаем контейнер для спрайтов барабанов
    reelsContainer = new Container();
    app.stage.addChild(reelsContainer);
    app.stage.setChildIndex(reelsContainer, 1)

    // Create reels
    for (let i = 0; i < 3; i++) {
      const reel: Sprite[] = [];
      for (let j = 0; j < 10; j++) {
        const symbolIndex = Math.floor(Math.random() * textures.length);
        const sprite = new Sprite(textures[symbolIndex]);
        sprite.y = j * reelsCell;
        sprite.x = i * reelsCell;
        reelsContainer.addChild(sprite);
        reel.push(sprite);
      }
      reels.push(reel);
    }

    // Создаем контейнер для графических элементов (линия выигрыша)
    graphicsContainer = new Container();
    app.stage.addChild(graphicsContainer);
    app.stage.setChildIndex(graphicsContainer, 2)
    // Создаем объект Graphics для рисования выигрышной линии
    graphicsContainer.addChild(winLineGraphics);
    graphicsContainer.setChildIndex(winLineGraphics, 0)

    const uiContainer = new Container();
    app.stage.addChild(uiContainer);
    app.stage.setChildIndex(uiContainer, 2)

    spinButtonSprite = new Sprite(activeSpinButtonTexture);
    spinButtonSprite.interactive = true;
    spinButtonSprite.anchor.set(0.5)
    uiContainer.addChild(spinButtonSprite);

    refillButtonSprite = new Sprite(refillButtontexture);
    refillButtonSprite.interactive = true;
    refillButtonSprite.anchor.set(0.5)
    uiContainer.addChild(refillButtonSprite);

    spinButtonSprite.on('pointerdown', () => {
      startSpin();
    });

    const textStyle = new TextStyle({
      fontFamily: 'Arial',
      fontSize: 16,
      fill: '#ffffff',
      stroke: '#000000',
      align: 'center',
    });


    // Создаем текстовый объект для отображения результата
    balanceText = new Text({ style: textStyle });
    balanceText.anchor.set(0.5)
    uiContainer.addChild(balanceText);

    // Создаем текстовый объект для отображения результата
    resultText = new Text({ style: textStyle });
    resultText.anchor.set(0.5)
    uiContainer.addChild(resultText);

    // Создаем текстовый объект для остатка спинов
    spinsText = new Text({ style: textStyle });
    spinsText.anchor.set(0.5)
    uiContainer.addChild(spinsText);


    // Обработчик клика
    refillButtonSprite.on('pointerdown', () => {
      // Открытие нового окна
      const apiHost = process.env.API_HOST || 'http://localhost:3000';
      const refillUrl = `${apiHost}/refill?uid=${getUID()}`

      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.openLink(refillUrl, {
          try_instant_view: true,
          try_browser: true,
          hide_webapp: false, // Скрыть мини-приложение после открытия ссылки (false - оставить открытым)
          is_external: false, // Открыть ссылку во внешнем браузере (true) или внутри Telegram (false)
        });
        setRefillButtonActive(false);
        monitorRefillWindow();

      } else {
        refillWindow = window.open(refillUrl, '_blank', 'width=600,height=400');

        if (refillWindow) {
          console.log('Открыто окно Refill', refillUrl);

          // Деактивируем кнопку до закрытия окна
          setRefillButtonActive(false);

          // Запускаем мониторинг состояния окна
          monitorRefillWindow();
        } else {
          console.warn('Не удалось открыть окно Refill. Проверьте настройки браузера.');
        }
      }
    })

    window.addEventListener('focus', () => {
      // Проверяем, было ли открыто окно и закрыто ли оно
      if (refillWindow && refillWindow.closed) {
        onRefillWindowClosed();
      }

      requestBalance();
    });

    // Setup WebSocket
    setupWebSocket();

    window.addEventListener('resize', resize);
    resize();

  } catch (error) {
    console.error('Error loading textures:', error);
  }
}

async function loadAssets(): Promise<void> {
  // Загрузка обычных текстур
  for (const symbol of symbols) {
    const texture = await Assets.load(symbol);
    textures.push(texture as Texture);
  }

  // Загрузка 'win' текстур
  for (const symbolWin of symbolsWin) {
    const textureWin = await Assets.load(symbolWin);
    texturesWin.push(textureWin as Texture);
  }

  smLogoTexture = await Assets.load(smLogoImg)
  machineBgTexture = await Assets.load(machineBg)

  activeSpinButtonTexture = await Assets.load(activeSpinButton)
  inactiveSpinButtonTexture = await Assets.load(inactiveSpinButton)
  refillButtontexture = await Assets.load(refillButton)
}

// WebSocket setup
let ws: WebSocket;
function setupWebSocket() {
  const wsUrl = process.env.WS_URL || 'ws://localhost:3001';
  ws = new WebSocket(wsUrl);

  ws.onopen = () => {
    console.log('WebSocket connection established');
    const message = { type: 'balance', data: { uid: getUID() } };
    ws.send(JSON.stringify(message));
  };

  ws.onmessage = (event) => {
    const data = JSON.parse(event.data)
    if (data.type === 'result') {
      // as { type: string; result: SpinResult };
      // Получили результат от сервера
      spinResult = data.result;
    }

    if (data.type === 'balance') {
      // Получили баланс от сервера
      updateBalance(data.result.balance)
    }

  };
}

function resize() {

  let maxWidth = (document.getElementById('game-container') as HTMLDivElement).getBoundingClientRect().width
  let maxHeight = (document.getElementById('game-container') as HTMLDivElement).getBoundingClientRect().height

  const headMinHeight = 150
  const uiContainerHeight = 200

  const bodyHeight = reelsContainerDefaultWidth + uiContainerHeight + headMinHeight


  scale = bodyHeight > maxHeight ? maxHeight / bodyHeight : 1

  const reelsContainerOffsetX = (maxWidth - reelsContainerDefaultWidth * scale) / 2
  const reelsContainerOffsetY = headMinHeight * scale

  console.log(`resize maxWidth:${maxWidth}, maxHeight:${maxHeight}, scale:${scale}`)

  reelsContainer.x = reelsContainerOffsetX
  reelsContainer.y = reelsContainerOffsetY
  reelsContainer.mask = new Graphics()
    .fill(0xffffff)
    .rect(reelsContainerOffsetX, reelsContainerOffsetY, reelsContainerDefaultWidth * scale, reelsContainerDefaultWidth * scale)
    .fill();

  reelsContainer.scale.set(scale);

  bgContainer.scale.set(scale);

  smLogoSprite.x = maxWidth / 2;
  smLogoSprite.y = 20
  smLogoSprite.scale.set(0.5);

  machineBgSprite.x = maxWidth / 2;
  machineBgSprite.y = 70
  machineBgSprite.scale.set(0.85);

  graphicsContainer.scale.set(scale);
  graphicsContainer.x = reelsContainer.x;
  graphicsContainer.y = reelsContainer.y;

  spinButtonSprite.width = spinButtonSprite.width * scale;
  spinButtonSprite.height = spinButtonSprite.height * scale;

  // Размещаем кнопку под барабанами
  spinButtonSprite.x = maxWidth / 2;
  spinButtonSprite.y = reelsContainer.y + reelsContainer.mask.height + 65 + spinButtonSprite.height / 2; // Отступ 10 пикселей под барабанами

  spinButtonSprite.mask = new Graphics()
    .fill(0xffffff)
    .circle(spinButtonSprite.x, spinButtonSprite.y, spinButtonSprite.width / 2)
    .fill();


  refillButtonSprite.width = refillButtonSprite.width * scale;
  refillButtonSprite.height = refillButtonSprite.height * scale;

  // Размещаем кнопку под барабанами
  refillButtonSprite.x = spinButtonSprite.x
  refillButtonSprite.y = spinButtonSprite.y

  refillButtonSprite.mask = new Graphics()
    .fill(0xffffff)
    .circle(spinButtonSprite.x, spinButtonSprite.y, spinButtonSprite.width / 2)
    .fill();

  spinsText.x = maxWidth / 2 + 125;
  spinsText.y = spinButtonSprite.y + 8

  balanceText.x = maxWidth / 2 - 130;
  balanceText.y = spinButtonSprite.y + 8

  resultText.x = maxWidth / 2;
  resultText.y = reelsContainer.y + reelsContainer.mask.height + 20;
}


// Variables to control animation
let spinning = false;
let reelsStopped = 0;
// let winningPositions: number[] = [];
let visibleSymbols: Sprite[][] = [[], [], []]; // Массив видимых символов на барабанах

let spinResult: SpinResult | null = null;

function startSpin() {
  if (spinning) return;

  spinning = true;
  reelsStopped = 0;
  spinResult = null;

  // Деактивируем кнопку
  setSpinButtonActive(false);
  setSpinButtonAnimation(false);

  // winningPositions = []; // Сброс выигрышных позиций
  resultText.text = '...';

  // Очистка предыдущей выигрышной линии
  winLineGraphics.clear();

  // Сброс анимаций и текстур
  for (const reel of reels) {
    for (const sprite of reel) {
      sprite.scale.set(1);
      const winTextureIndex = texturesWin.indexOf(sprite.texture);
      if (winTextureIndex !== -1) {
        // Если текущая текстура - 'win' версия, возвращаем обычную
        sprite.texture = textures[winTextureIndex];
      }
    }
  }

  if (!sound.exists('backgroundSound')) {
    sound.add('backgroundSound', backgroundSound);
  }

  if (!playing.get('backgroundSound')) {
    sound.play('backgroundSound', { loop: true, volume: soundVolumes.backgroundSound.max });
    playing.set('backgroundSound', true)
  }

  if (playing.get('backgroundSound')) {
    if (sound.exists('backgroundSound')) {
      if (soundVolumes.backgroundSound.vc) {
        soundVolumes.backgroundSound.vc.cancel()
      }
      sound.volume('backgroundSound', soundVolumes.backgroundSound.max)
    }
  }

  // Отправляем запрос на сервер
  const message = { type: 'spin', data: { uid: getUID() } };
  ws.send(JSON.stringify(message));

  // Начинаем вращение барабанов
  for (let i = 0; i < reels.length; i++) {
    spinReel(i);
  }
}


function decreaseVolume(
  alias: string,
  max: number,
  min: number,
  delay: number = 1000,
  ttl: number = 1000
): { cancel: () => void } {
  let isCancelled = false;
  let timeoutId: NodeJS.Timeout | null = null;
  let animationFrameId: number | null = null;

  if (playing.get(alias) && sound.exists(alias)) {
    // Устанавливаем начальную громкость на max
    sound.volume(alias, max);

    // Ждем задержку перед началом уменьшения громкости
    timeoutId = setTimeout(() => {
      if (isCancelled || !playing.get(alias) || !sound.exists(alias)) {
        // Звук больше не играет, не существует или отменен, прекращаем выполнение
        return;
      }

      const startTime = performance.now();
      const duration = ttl;
      const initialVolume = max;
      const finalVolume = min;

      function step(currentTime: number) {
        if (isCancelled) {
          // Прекращаем уменьшение громкости
          return;
        }

        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const currentVolume =
          initialVolume - (initialVolume - finalVolume) * progress;

        sound.volume(alias, currentVolume);

        if (progress < 1) {
          animationFrameId = requestAnimationFrame(step);
        } else {
          // Устанавливаем конечную громкость и завершаем
          sound.volume(alias, finalVolume);
        }
      }

      animationFrameId = requestAnimationFrame(step);
    }, delay);
  }

  // Возвращаем объект с методом cancel
  return {
    cancel: () => {
      isCancelled = true;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    },
  };
}

// Function to animate reel spinning
function spinReel(reelIndex: number) {

  const reel = reels[reelIndex];
  const reelSpeed = 50 + reelIndex * 20; // Разная скорость для каждого барабана
  const ticker = new Ticker();

  let reelContainerHeight = reelsContainerDefaultWidth
  console.log('reelContainerHeight', reelContainerHeight)

  const cellSize = 100;

  ticker.add(() => {
    for (const sprite of reel) {
      sprite.y += reelSpeed;
      if (sprite.y >= reelContainerHeight) {
        sprite.y = sprite.y - (reelContainerHeight + cellSize);
        // Меняем текстуру символа на случайную
        const randomTexture = getRandomTexture();
        sprite.texture = randomTexture;
      }
    }
  });

  ticker.start();

  // Останавливаем барабан через определенное время
  setTimeout(() => {
    ticker.stop();

    // Выравниваем символы по сетке
    for (const sprite of reel) {
      sprite.y = Math.round(sprite.y / cellSize) * 100 % (reelContainerHeight + cellSize);
    }

    updateVisibleSymbols(reelIndex);

    // Если результат спина получен, устанавливаем символы из результата
    if (spinResult) {
      const reelResult = getReelResult(reelIndex);

      for (let positionIndex = 0; positionIndex < 3; positionIndex++) {
        const sprite = visibleSymbols[reelIndex][positionIndex];
        const symbolIndex = reelResult[positionIndex] - 1;
        try {
          sprite.texture = textures[symbolIndex];
        } catch (e: any) {
          console.log('ERRRR symbolIndex, reelIndex, reelResult', symbolIndex, reelIndex, reelResult)
        }
      }
    }

    // Воспроизведение звука остановки барабана
    if (!sound.exists('reelStopSound')) {
      sound.add('reelStopSound', reelStopSound);
    }
    sound.play('reelStopSound', { volume: soundVolumes.reelStopSound.max });


    reelsStopped++;
    if (reelsStopped === reels.length) {

      // Активируем кнопку после остановки барабанов
      setSpinButtonActive(true);
      setSpinButtonAnimation(true);

      soundVolumes.backgroundSound.vc = decreaseVolume('backgroundSound',
        soundVolumes.backgroundSound.max,
        soundVolumes.backgroundSound.min, 2000, 1000
      )

      // Все барабаны остановлены
      if (spinResult) {
        checkWin(spinResult);
      } else {
        resultText.text = 'Error';
      }
      spinning = false;
    }
  }, 500 + reelIndex * 300); // Время вращения каждого барабана
}

function getReelResult(reelIndex: number): number[] {
  const reelResult = [];
  for (let positionIndex = 0; positionIndex < 3; positionIndex++) {
    const index = positionIndex * 3 + reelIndex; // Индекс символа в `spinResult`
    reelResult.push(spinResult!.numbers![index]);
  }
  return reelResult;
}

function updateVisibleSymbols(reelIndex: number) {
  const reel = reels[reelIndex];
  const positions = [0, 100, 200]; // Позиции видимых символов на барабане
  visibleSymbols[reelIndex] = []; // Очищаем предыдущие ссылки

  for (const sprite of reel) {
    if (positions.includes(Math.round(sprite.y))) {
      const positionIndex = positions.indexOf(Math.round(sprite.y));
      visibleSymbols[reelIndex][positionIndex] = sprite;
    }
  }
}

// Get a random texture
function getRandomTexture(): Texture {
  const randomIndex = Math.floor(Math.random() * textures.length);
  // return textures[randomIndex];
  const arr = [textures, texturesWin]
  const textSource = arr[Math.floor(Math.random() * 2)]
  return textSource[randomIndex];
}

function checkWin(result: SpinResult) {

  animateWinningSymbols(result);
  winLineGraphics.clear();
  for (const line of result.lines!) {
    drawWinLine(line);
  }

  if (result.lines?.length) {

    const winf = Math.floor((result.win || 0) * 100) / 100
    const mlpf = Math.floor((result.multiplier) || 0 * 100) / 100

    resultText.text = `WIN +${winf}    x${mlpf}`;
    // drawWinLine(winLine.positions);
    // animateWinningSymbols();

    // Воспроизведение звука выигрыша
    if (!sound.exists('winSound')) {
      sound.add('winSound', winSound);
    }
    sound.play('winSound', { volume: soundVolumes.winSound.max });

  } else {
    resultText.text = `LOSE -${result.lose}`;
    // Воспроизведение звука проигрыша
    if (!sound.exists('loseSound')) {
      sound.add('loseSound', loseSound);
    }
    sound.play('loseSound', { volume: soundVolumes.loseSound.max });
  }
  updateBalance(result.balance)
}

function updateBalance(balance: Balance | null) {
  // state.balance = balance
  const amf = Math.floor((balance?.amount || 0) * 100) / 100
  balanceText.text = `$ ${amf}`
  if (balance?.spins) {
    setSpinButtonActive(true);
    spinsText.text = `Spins: ${balance?.spins}`
    setRefillButtonActive(false);
  } else {
    setSpinButtonActive(false);
    spinsText.text = ``
    setRefillButtonActive(true)
  }
}

//
// Перепиши функцию для горизонтальной нмерации
// [0, 1, 2]
// [3, 4, 5]
// [6, 7, 8]
function getCellCenterCoordinates(index: number): { x: number; y: number } {
  const cellSize = 100;
  const x = (index % 3) * cellSize + cellSize / 2;
  const y = Math.floor(index / 3) * cellSize + cellSize / 2;
  return { x, y };
}
// function getCellCenterCoordinates(index: number): { x: number; y: number } {
//   const cellSize = 100;
//   const x = Math.floor(index / 3) * cellSize + cellSize / 2;
//   const y = (index % 3) * cellSize + cellSize / 2;
//   return { x, y };
// }

function drawWinLine(positions: number[]) {
  // Получаем координаты символов
  const coords = positions.map(index => {
    const { x, y } = getCellCenterCoordinates(index);
    return { x, y };
  });

  //Рисуем линию через символы
  winLineGraphics
    .moveTo(coords[0].x, coords[0].y);
  for (let i = 1; i < coords.length; i++) {
    winLineGraphics.lineTo(coords[i].x, coords[i].y);
  }
  winLineGraphics.stroke({ texture: Texture.WHITE, width: 3, color: 0xFFFFFF })
}

function animateWinningSymbols(result: SpinResult) {
  for (const line of result.lines!) {
    for (const index of line) {
      const { reelIndex, positionIndex } = getReelAndPosition(index);
      const sprite = visibleSymbols[reelIndex][positionIndex];
      if (sprite) {
        // Заменяем текстуру на 'win' версию
        const symbolIndex = result.numbers![index] - 1;
        sprite.texture = texturesWin[symbolIndex];
      }
    }
  }
}

// Вспомогательные функции getReelAndPosition и getSpriteAt

// Перепиши функцию для горизонтальной нумерации
// [0, 1, 2]
// [3, 4, 5]
// [6, 7, 8]
function getReelAndPosition(index: number): { reelIndex: number; positionIndex: number } {
  const reelIndex = index % 3; // Номер барабана (столбец): 0, 1, 2
  const positionIndex = Math.floor(index / 3); // Позиция на барабане (строка): 0, 1, 2
  return { reelIndex, positionIndex };
}

let spinningAnimation: Ticker | null = null;
function setSpinButtonActive(active: boolean) {
  if (active) {
    spinButtonSprite.texture = activeSpinButtonTexture;
    spinButtonSprite.interactive = true;
  } else {
    spinButtonSprite.texture = inactiveSpinButtonTexture;
    spinButtonSprite.interactive = false;
  }
}
function setSpinButtonAnimation(active: boolean) {
  if (active) {
    if (spinningAnimation) {
      spinningAnimation.stop();
      spinningAnimation = null;
      spinButtonSprite.rotation = 0; // Сбрасываем поворот
    }
  } else {
    // Добавляем анимацию вращения
    spinningAnimation = new Ticker();
    spinningAnimation.add(() => {
      spinButtonSprite.rotation += 0.2;
    });
    spinningAnimation.start();
  }
}

function monitorRefillWindow() {
  const checkInterval = 500; // Проверять каждые 500 миллисекунд

  const intervalId = setInterval(() => {
    if (refillWindow && refillWindow.closed) {
      // Останавливаем интервал
      clearInterval(intervalId);
      // Выполняем нужные действия после закрытия окна
      onRefillWindowClosed();
    }
  }, checkInterval);
}

function onRefillWindowClosed() {
  console.log('onRefillWindowClosed: Обновление баланса пользователя после Refill');
  requestBalance()
}

function requestBalance() {
  const message = { type: 'balance', data: { uid: getUID() } };
  ws.send(JSON.stringify(message));
}

function setRefillButtonActive(active: boolean) {
  if (active) {
    refillButtonSprite.texture = refillButtontexture;
    refillButtonSprite.visible = true
    refillButtonSprite.interactive = true;
  } else {
    refillButtonSprite.texture = refillButtontexture;
    refillButtonSprite.visible = false
    refillButtonSprite.interactive = false;
  }
}

// Запуск инициализации
init();
